import { Alert, Button, Form, InputGroup, Modal } from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import * as yup from "yup";
import { APIService } from "../../api.service";

const schema = yup.object().shape({
  mobile: yup
    .string()
    // .matches(/^(?:254|\+254|0)?(7(?:(?:[129][0–9])|(?:0[0–8])|(4[0–1]))[0–9]{6})$/, "Number is not valid")
    .required(
      "Please provide a valid mobile number that is registerd on M-Pesa"
    ),
  amount: yup
    .number()
    .required("Please provide and amount that will be transfered")
    .min(0, "Amount should be greater then 0 kSH")
    .max(1000, "You can't transfer more then 1000 kSH at once."),
  remark: yup.string().required("Please add a remark of this payment."),
});

const Payout = ({to, mobile}) => {
  const [ShowPayoutModal, setShowPayoutModal] = useState(false);
  const payoutFrm = useFormik({
    initialValues:{
      userId: to,
      mobile: mobile,
      amount: '',
      remark: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      APIService.post('payout', values).then(res=>{
        const { data, error } = res.data;
        setShowPayoutModal(false);
        if(error != 0){
          Notiflix.Report.failure("Unable to make payout", data.toString());
        }
        Notiflix.Report.success(
          "Payout/Refund initiated succefully.",
          `${data.ResponseDescription} \n ConversationID: ${data.ConversationID}\n OriginatorConversationID: ${data.OriginatorConversationID} \n \n.`
        );
      }).catch(err=>{
        setShowPayoutModal(false);
        Notiflix.Report.failure("Unable to make payout", err.toString());
      });
    }
  });

  const ErrorCst = ({ field }) => {
    if (payoutFrm.errors[field]) {
      return <Alert variant="danger">{payoutFrm.errors[field]}</Alert>;
    }
    return <></>;
  };


  return (
    <>
      <Button
        size="sm"
        variant="info"
        onClick={() => {
          setShowPayoutModal(!ShowPayoutModal);
        }}
      >
        Make Refund / Doctor Payout
      </Button>
      <Modal
        show={ShowPayoutModal}
        onHide={() => {
          setShowPayoutModal(false);
        }}
        scrollable
      >
        <Modal.Header>
          <h2>Make Payment</h2>
        </Modal.Header>
        <Modal.Body>
          <h4>Refund or Doctors Payout</h4>
          <Form onSubmit={payoutFrm.handleSubmit}>
            <Form.Group>
              <Form.Label title="M-Pesa connected">
                To: (Mobile Number)
              </Form.Label>
              <InputGroup>
                {/* <InputGroup.Text id="mobile">+254</InputGroup.Text> */}
                <Form.Control
                  aria-describedby="mobile"
                  aria-label="M-Pesa activated mobile number"
                  placeholder="Eg. 254702010831"
                  onBlur={payoutFrm.handleBlur('mobile')}
                  onChange={payoutFrm.handleChange('mobile')}
                  defaultValue={mobile}
                />
              </InputGroup>
              <ErrorCst field={'mobile'} />
            </Form.Group>
            <Form.Group>
              <Form.Label title="Amount in kSH">Amount:</Form.Label>
              <InputGroup>
                <Form.Control
                  aria-describedby="amount"
                  aria-label="Amount to be transfered"
                  placeholder="Eg. 1.0"
                  type="number"
                  onBlur={payoutFrm.handleBlur('amount')}
                  onChange={payoutFrm.handleChange('amount')}
                />
                <InputGroup.Text id="amount">kSH</InputGroup.Text>
              </InputGroup>
              <ErrorCst field={'amount'} />
            </Form.Group>
            <Form.Group>
              <Form.Label title="Remark">Remark:</Form.Label>
              <InputGroup>
                <Form.Control
                  aria-describedby="remark"
                  aria-label="Amount to be transfered"
                  placeholder="Eg. Refund because consultion was not completed"
                  as="textarea"
                  onBlur={payoutFrm.handleBlur('remark')}
                  onChange={payoutFrm.handleChange('remark')}
                />
              </InputGroup>
              <ErrorCst field={'remark'} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              payoutFrm.submitForm();
            }}
            variant={'success'}
          >
            Make Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Payout;
