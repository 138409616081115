import React, { useState } from 'react';
import { Modal, Button, Form, Field, ButtonGroup, ToggleButtonGroup, ToggleButton } from '@themesberg/react-bootstrap';
import { Field as FromikField, ErrorMessage, useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import Notiflix from 'notiflix';

import { APIService } from '../api.service';

const schema = yup.object().shape({
  name: yup.string().min(2, 'Speciality Name too short').required('Speciality name is required'),
  visible: yup.boolean().required('Please select visibility.'),
});

export const SpecialityCreateEdit = (props) => {
  const {record, callbackOnSucces} = props;
  const [RecordData, setRecordData] = useState({
    id: record.id || undefined,
    name: record.name || '',
    visible: record.visible ? 1 : 0
  });
  const login = useSelector(state => state.login)

  let hideFlag = false;
  const hide = (a) => {
    if (!hideFlag) {
      return false;
    }
    if (props.onHide) {
      props.onHide();
    }
  };
  const forceHide = () => {
    hideFlag = true;
    hide();
    hideFlag = false;
  }

  const saveRecord = (values, formik) => {
    hideFlag = true;
    const formData = new FormData();
    values.id = RecordData.id || null;
    Object.keys(values).map((key) => {
      formData.append(key, values[key]);
    });
    APIService.post("create-edit-speciality", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (callbackOnSucces) {
          callbackOnSucces();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const recordFrm = useFormik({
    initialValues: RecordData,
    validationSchema: schema,
    onSubmit: saveRecord,
  });
  return (
    <Modal as={Modal.Dialog} centered show={props.show != 0} onHide={forceHide}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={forceHide} />
      </Modal.Header>
        <Form onSubmit={recordFrm.submitForm}>
          <Modal.Body>
            {/* <pre>{JSON.stringify(errors,null,5)}</pre> */}
            <input type="hidden" name="id" value={RecordData.id} />
            <Form.Group>
              <label>Speciality Name</label>
              <Form.Control
                placeholder="Eg. Orthopedic"
                value={recordFrm.values.name}
                name="name"
                onChange={recordFrm.handleChange}
                onBlur={recordFrm.handleBlur}
              ></Form.Control>
              { recordFrm.errors.name ? <div className="text-danger">{ recordFrm.errors.name }</div> : null }
            </Form.Group>
            <Form.Group>
              <ToggleButtonGroup name='visible' value={recordFrm.values.visible} onChange={(value)=>{ recordFrm.setFieldValue('visible', value); }} onBlur={recordFrm.handleBlur}>
                <ToggleButton value={1} id='v1' checked={RecordData.visible == 1} variant="outline-success">Visible</ToggleButton>
                <ToggleButton value={0} id='v0' checked={RecordData.visible == 0} variant="outline-danger">Hidden</ToggleButton>
              </ToggleButtonGroup>
              { recordFrm.errors.visible ? <div className="text-danger">{ recordFrm.errors.visible }</div> : null }
            </Form.Group>
            {/* <Form.Group>
              <label>Diposit Type</label>
              <Form.Select placeholder="Please Select DepositType" name="depositType" onChange={handleChange} onBlur={handleBlur}>
                <option value="daily">Daily</option>
                <option value="multiple">Multiple</option>
                <option value="single">Single</option>
              </Form.Select>
              <ErrorMessage name="depositType" className="text-danger" component="p" />
            </Form.Group> */}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="secondary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
    </Modal>
  );
};
