import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Col, Row, Button, Dropdown, ButtonGroup, Table, Alert, Card, Image, Form } from '@themesberg/react-bootstrap';
import Notiflix from 'notiflix';
import moduleName from 'react-datetime'

import { PageVisitsTable } from '../components/Tables';
import { trafficShares, totalOrders } from '../data/charts';

import { useSelector, useDispatch } from 'react-redux';
import { setDoctorsList } from '../slices/doctorsSlice';
import { APIService } from '../api.service';

import {CreateEditDoctor} from '../components/CreateEditDoctor'
import Code from '../components/Code';
import moment from 'moment-timezone';
import Payout from './components/Payout';

let lastUpdate = new Date().getTime();
console.log(lastUpdate);

const PaymentCard = ({Payments, getPayments}) => {
  const [Total, setTotal] = useState(0);
  const [ShowPayments, setShowPayments] = useState(false);
  useEffect(() => {
    let total = 0;
    Payments.map((payment, index) => {
      total += payment.amount;
    });
    setTotal(total);
  
    return () => {
      // second
    }
  }, [])
  
  return (
    <>
      <h5 title={Payments[0].user.balance}>Balance: {Total.toFixed(2)}</h5>
      <Row className="justify-content-md-center">
        <Col xs={12} className="d-none d-sm-block">
          <Form.Check
            type="switch"
            id="showpmt"
            label={ShowPayments ? "Hide Payments" : "Show Payments"}
            onChange={(e) => {
              setShowPayments(e.target.checked);
            }}
          />
          {Payments.length > 0 && ShowPayments && (
            <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Full Name</td>
                    <td>Amount</td>
                    <td>Details / Remarks</td>
                    <td>Created</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {Payments.map((payment, index) => (
                    <tr
                      key={index}
                      style={{
                        background: payment.amount < 0 ? "#ffd1b3" : "#CEFFE1",
                      }}
                    >
                      <td>{payment.id}</td>
                      <td>
                        <Image
                          rounded
                          src={payment.user.profile_pic}
                          height={40}
                          alt={payment.user.profile_pic}
                          onError={(e) => {
                            e.target.src = `https://dummyimage.com/200x200/#DEEFFA/#7290A9&text=${payment.user.fullname}`;
                          }}
                        ></Image>
                        <br/>
                        {payment.user.full_name}
                      </td>
                      <td align="right">KSh {payment.amount}</td>
                      <td>
                      {payment.remarks && payment.remarks.includes("stkCallback") ? (
                          <Code
                            style={{ width: 200 }}
                            language="json"
                            code={JSON.stringify(
                              JSON.parse(payment.remarks),
                              null,
                              2
                            )}
                          />
                        ) : (
                          // <pre>{JSON.stringify(JSON.parse(payment.remarks), null, 2)}</pre>
                          <>{payment.remarks}</>
                        )}
                        {payment.api_response != null && (<>
                          <hr />
                          Callback: <br /><Code
                            style={{ width: 200 }}
                            language="json"
                            code={JSON.stringify(
                              JSON.parse(payment.api_response),
                              null,
                              2
                            )}
                          />
                        </>)}
                      </td>
                      {/* <td>{doctor.date}</td> */}
                      {/* <td>{new Date(payment.created_at).toDateString()}</td> */}
                      <td>
                        {moment(payment.created_at).format(
                          "ddd, MMM Do YYYY, h:mm A"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const Payments = ({history}) => {
  const [ShowCreateEditDoctor, setShowCreateEditDoctor] = useState(false)
  const [Payments, setPayments] = useState([]);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const getPayments = () => {
    APIService.get('payment-list', {
      params: {
        // "single": false,
        // "withBranches": false
      },
    }).then((response) => {
      Notiflix.Notify.info('Fetched Doctor\'s list',{
        clickToClose: true,
        timeout: 2000,
      });
      const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      setPayments(groupBy(response.data.data, 'user_id'));
      // dispatch(setDoctorsList(response.data.doctors));
      lastUpdate = new Date().getTime();
    });
  };

  useEffect(() => {
    getPayments();
  }, []);

  const enableOrDisable = (id, enableOrDisable) => {
    Notiflix.Confirm.show(`${!enableOrDisable ? 'Enable' : 'Disable'} Doctor/User`,`Are you sure to ${!enableOrDisable ? 'Enable' : 'Disable'} this Doctor/User`,'Yes','Cancel',()=>{
      APIService.post(
        "enable-disable",
        { id: id },
      ).then((response) => {
        Notiflix.Notify.success(response.data.message);
        getPayments();
      });
    })
  };

  const deleteDoctorUser = (id) => {
    Notiflix.Confirm.show(`Delete Doctor`,`Are you sure to this Doctor?`,'Yes','Cancel',()=>{
      APIService.post(
        "delete-doctor",
        { id: id },
      ).then((response) => {
        Notiflix.Notify.success(response.data.message);
        getPayments();
      });
    })
  }



  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <h4>Payments</h4>
        {/* <pre>{JSON.stringify(Payments,null,2)}</pre> */}
      </div>
      <Button onClick={getPayments}>Refresh</Button>
      {Object.keys(Payments).map((key,i)=>{
        const paymenGroupItem = Payments[key];
        return (
          <Card border="info" className="mt-2" key={i}>
            <Card.Body style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <h5>{paymenGroupItem[0].user.full_name}</h5>
              <a href={`mailto:${paymenGroupItem[0].user.email}`}>
                {paymenGroupItem[0].user.email}
              </a>
              <br />
              <a href={`tel:${paymenGroupItem[0].user.phone}`}>
                {paymenGroupItem[0].user.phone}
              </a>
              <br />
              <Payout to={paymenGroupItem[0].user.id} mobile={paymenGroupItem[0].user.phone} />
              <PaymentCard
                Payments={paymenGroupItem}
                getPayments={getPayments}
              />
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
};
export default Payments;
