import React, { useEffect } from "react";
import { Nav } from "@themesberg/react-bootstrap";
import Notiflix from 'notiflix';

import { Routes } from "../routes";

import logo from "../assets/img/logo.png";

import $ from "jquery";
// import '../assets/theme/js/active';
import "../assets/theme/css/responsive.css";
import "../assets/theme/style.css";
import { Form, Formik, useFormik } from "formik";

const DeleteAccount = () => {
  const deleteAccountFrm = useFormik({
    initialValues: {
      usernameOrEmail: "",
      reason: ""
    },
    onSubmit: (values) => {
      Notiflix.Notify.success('You will recieve a link in your mailbox to remove your account and data permanently.');
    },
  });

  useEffect(() => {
    $("#preloader").fadeOut("slow", function () {
      $(this).remove();
    });
    return () => {
      // second
    };
  }, []);

  return (
    <>
      {/* <!-- ***** Preloader Start ***** --> */}
      <div id="preloader">
        <div className="selene-preloader"></div>
      </div>

      {/* <!-- ***** Header Area Start ***** --> */}
      <header className="header_area animated">
        <div className="container-fluid">
          <div className="row align-items-center">
            {/* <!-- Menu Area Start --> */}
            <div className="col-12">
              <div className="menu_area">
                <nav className="navbar navbar-expand-lg navbar-light">
                  {/* <!-- Logo --> */}
                  <a
                    className="navbar-brand"
                    href="#"
                    style={{ marginLeft: "-75px", marginTop: "25px" }}
                  >
                    <img
                      src={logo}
                      style={{ width: "200px", height: "200px" }}
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#selene-navbar"
                    aria-controls="selene-navbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  {/* <!-- Menu Area --> */}
                  <div className="collapse navbar-collapse" id="selene-navbar">
                    <ul className="navbar-nav ml-auto" id="nav">
                      <li className="nav-item active">
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>
                      {/* <li className="nav-item dropdown">
                                        <div className="dropdown-menu" aria-labelledby="seleneDropdown">
                                            <a className="dropdown-item" href="index.html">Home</a>
                                        </div>
                                    </li> */}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ***** Header Area End ***** --> */}

      {/* <!-- ***** Wellcome Area Start ***** --> */}
      <section className="welcome_area clearfix" id="home">
        {/* <!-- Shape --> */}
        <div className="welcome-bg-shape">
          <img
            className="welcome-first-shape"
            src="img/core-img/welcome-bg-1.png"
            alt=""
          />
          <img
            className="welcome-second-shape"
            src="img/core-img/welcome-bg-2.png"
            alt=""
          />
        </div>

        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 pb-9">
              <fieldset>
                <legend>
                  <h2>Delete Account</h2>
                </legend>
                <Formik>
                  <Form
                    onSubmit={deleteAccountFrm.handleSubmit}
                    onReset={deleteAccountFrm.handleReset}
                  >
                    <div class="form-group">
                      <label>Email or Username:</label>
                      <input
                        onChange={deleteAccountFrm.handleChange}
                        onBlur={deleteAccountFrm.handleBlur}
                        name="usernameOrEmail"
                        type="text"
                        class="form-control"
                        placeholder="eg. someusername / myemail@domain.com"
                        aria-label="Email or Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div class="form-group">
                      <label>Reason to delete:</label>
                      <textarea
                        onChange={deleteAccountFrm.handleChange}
                        onBlur={deleteAccountFrm.handleBlur}
                        name="reason"
                        class="form-control"
                        placeholder="eg. reason to delete your account...."
                        aria-label="Reason to delete"
                        aria-describedby="basic-addon2"
                      ></textarea>
                    </div>
                    <button class="btn btn-primary" type="submit">
                      Send delete request
                    </button>
                  </Form>
                </Formik>
              </fieldset>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** Wellcome Area End ***** --> */}

      {/* <!-- ***** App Feature Area Start ***** --> */}
      {/* {AppFeatures()} */}
      {/* <!-- ***** App Feature Area End ***** --> */}

      {/* <!-- ***** Pricing Plane Area Start ***** --> */}
      {/* {PricingPlans()} */}
      {/* <!-- ***** Pricing Plane Area End ***** --> */}

      {/* <!-- ***** Footer Area Start ***** --> */}
      <footer className="footer-area text-center clearfix">
        {/* <!-- Shape 1 --> */}
        <div className="footer-bg-shape">
          <img
            src="img/core-img/footer-bg.png"
            style={{ height: "243px", width: "100%" }}
            alt="Footer Shape"
          />
        </div>
        {/* <!-- Shape 2 --> */}
        {/* <div className="footer-bg-shape-2">
            <img src="img/core-img/price-table.png" alt="" />
        </div> */}

        <div className="container h-100">
          <div className="row h-100 justify-content-center">
            <div className="col-12 col-md-8">
              {/* <div className="footer-content">
                        <h2>Download App</h2>
                        <h4>Get you first consultation free. </h4>
                    </div>
                    <div className="app-download-area">
                        <div className="app-download-btn active">
                            <!-- Google Store Btn -->
                            <a href="#"><i className="fa fa-android"></i><p className="mb-0"><span>available on</span> Google Store</p></a>
                        </div>
                        <div className="app-download-btn">
                            <!-- Apple Store Btn -->
                            <a href="#"><i className="fa fa-apple"></i><p className="mb-0"><span>available on</span> Apple Store</p></a>
                        </div>
                    </div> */}
              <div className="footer-social-info">
                {/* <a href="#">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a> */}
                <Nav.Link
                  className=""
                  title="Privacy policy"
                  href={Routes.PrivacyPolicy.path}
                >
                  Privacy policy
                </Nav.Link>
                <Nav.Link
                  className=""
                  title="Contact Us"
                  href={Routes.ContactUs.path}
                >
                  Contact Us
                </Nav.Link>
              </div>
              {/* <!-- Foooter Text--> */}
              <div className="copyright-text">
                <p>
                  {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script> All
                  rights reserved{" "}
                  <i className="fa fa-heart-o" aria-hidden="true"></i> by{" "}
                  <a href="https://mobidoctor.org" target="_blank">
                    Mobidoctor
                  </a>
                  {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ***** Footer Area End ***** --> */}
      {/* </div> */}
    </>
  );
};
export default DeleteAccount;
