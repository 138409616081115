import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Alert, Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Image } from '@themesberg/react-bootstrap';
import { Link, Redirect } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setAuthUser } from './../slices/loginSlice';

import { Routes } from '../routes';
import BgImage from '../assets/img/illustrations/signin.svg';
import { APIService } from '../api.service';

export default (props) => {
  console.log(props);
  const [Msg, setMsg] = useState('');

  useEffect(() => {
    debugger;
    let x = new URL(window.location);
    let k = x.searchParams;
    if(JSON.parse(k.get('status'))){
      setMsg(' Verified successfully.');
    }else{
      setMsg('Verification failed.');
    }
    return () => {
    }
  }, [])
  
  
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.Dashboard.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <Image src={require('../assets/img/logo.png')} />
                  <h4 className="mb-0">Email {Msg}</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
