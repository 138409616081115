import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Code from '../components/Code';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faCartArrowDown,
  faChartPie,
  faChevronDown,
  faClipboard,
  faCommentDots,
  faFileAlt,
  faPlus,
  faRocket,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Card,
  Form,
} from "@themesberg/react-bootstrap";
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";
import {
  Formik,
  Form as FormikForm,
  Field as FromikField,
  ErrorMessage,
  useFormik,
} from "formik";
import * as yup from "yup";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { APIService } from "../api.service";
import { setAppConfig } from "../slices/appSlice";
import Notiflix from "notiflix";

const schema = yup.object().shape({
  price: yup
    .string()
    .required("Please provide valid price.")
    .min(0, "Amount too low."),
});

export default () => {
  const dispatch = useDispatch();
  const APP = useSelector(state => state.app);
  const getSettings = () => {
    APIService.get('settings').then((res)=>{
      const dt = {};
      res.data.data.map(a=>{
        dt[a.skey] = a.svalue;
      });
      dispatch(setAppConfig(dt));
    })
  };
  const globalConfigFrm = useFormik({
    initialValues: {
      xyz: APP.xyz,
      bgUrl: APP.bgUrl,
      price: APP.price,
      enablePrice: APP.enablePrice,
      freeSubscription: APP.freeSubscription,
      B2C_API_USERNAME: APP.B2C_API_USERNAME,
      B2C_API_PWD: APP.B2C_API_PWD,
      BUSINESS_SHORT_CODE: APP.BUSINESS_SHORT_CODE,
    },
    validationSchema: schema,
    enableReinitialize:true,
    onSubmit: (values) => {
      if(values.enablePrice.length == 0){
        values.enablePrice = 0;
      }
      APIService.post("settings", values).then(res=>{
        window.location.reload();
      }).catch(err=>{
        Notiflix.Report.failure("Unable to save configuration", err.toString());
      })
    },
  });
  useEffect(() => {
    getSettings();
    console.log(APP)
    // debugger;
    return () => {
      // second
    }
  }, []);
  
  return (
    <>
      <Row>
        {/* <Col xs={12} xl={8}>
           <GeneralInfoForm /> 
        </Col> */}

        <Col xs={12} xl={6}>
          <Row>
            {APP.price && (
            <Card>
              <Card.Header>Global Configuration</Card.Header>
              <Card.Body>
                {/* <pre>{JSON.stringify(APP,null, 2)}</pre> */}
                <Form onSubmit={globalConfigFrm.handleSubmit}>
                  <Form.Group>
                    <Form.Label htmlFor="price">
                      Monthly usage price (KSh)
                    </Form.Label>
                    <Form.Control
                      defaultValue={APP.price}
                      name="price"
                      onChange={globalConfigFrm.handleChange}
                      onBlur={globalConfigFrm.handleBlur}
                      id="price"
                      type="text"
                      min={0}
                      size="sm"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="price">
                      Background URL
                    </Form.Label>
                    <Form.Control
                      defaultValue={APP.bgUrl}
                      name="bgUrl"
                      onChange={globalConfigFrm.handleChange}
                      onBlur={globalConfigFrm.handleBlur}
                      id="bgUrl"
                      type="text"
                      min={0}
                      size="sm"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="price">
                      Make Payment / Refund from (M-Pesa enabled mobile number to get push)
                    </Form.Label>
                    <Form.Control
                      defaultValue={APP.payout_from}
                      name="payout_from"
                      disabled={globalConfigFrm.values.enablePrice != 1}
                      onChange={globalConfigFrm.handleChange}
                      onBlur={globalConfigFrm.handleBlur}
                      id="payout_from"
                      type="text"
                      min={0}
                      size="sm"
                      title="Can be changed after EnablePricing is switched on"
                    />
                  </Form.Group>
                  <br />
                  <Form.Group controlId="enablePrice">
                    <Form.Check
                      type="switch"
                      id="enablePrice"
                      label="Enable App pricing"
                      value="1"
                      onChange={globalConfigFrm.handleChange("enablePrice")}
                      onBlur={globalConfigFrm.handleBlur("enablePrice")}
                      checked={globalConfigFrm.values.enablePrice == 1}
                    />
                  </Form.Group>
                  <Form.Group controlId="freeSubscription">
                    <Form.Check
                      type="switch"
                      id="freeSubscription"
                      label="Enable free subscription"
                      value="1"
                      onChange={globalConfigFrm.handleChange("freeSubscription")}
                      onBlur={globalConfigFrm.handleBlur("freeSubscription")}
                      checked={globalConfigFrm.values.freeSubscription == 1}
                    />
                  </Form.Group>
                  <hr />
                  <Form.Group>
                    <Form.Label htmlFor="BUSINESS_SHORT_CODE">
                     M-Pesa Business Short Code (Paybill)
                    </Form.Label>
                    <Form.Control
                      defaultValue={APP.BUSINESS_SHORT_CODE}
                      name="BUSINESS_SHORT_CODE"
                      onChange={globalConfigFrm.handleChange}
                      onBlur={globalConfigFrm.handleBlur}
                      id="BUSINESS_SHORT_CODE"
                      type="text"
                      min={0}
                      size="sm"
                    />
                  </Form.Group>
                  <hr />
                  <Form.Group>
                    <Form.Label htmlFor="B2C_API_USERNAME">
                      B2C API UserName (M-Pesa)
                    </Form.Label>
                    <Form.Control
                      defaultValue={APP.B2C_API_USERNAME}
                      name="B2C_API_USERNAME"
                      onChange={globalConfigFrm.handleChange}
                      onBlur={globalConfigFrm.handleBlur}
                      id="B2C_API_USERNAME"
                      type="text"
                      min={0}
                      size="sm"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="B2C_API_PWD">
                      B2C API Password (M-Pesa)
                    </Form.Label>
                    <Form.Control
                      defaultValue={APP.B2C_API_PWD}
                      name="B2C_API_PWD"
                      onChange={globalConfigFrm.handleChange}
                      onBlur={globalConfigFrm.handleBlur}
                      id="B2C_API_PWD"
                      type="text"
                      min={0}
                      size="sm"
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
              <Card.Footer>
                <Button
                  disabled={!globalConfigFrm.isValid}
                  onClick={() => {
                    globalConfigFrm.submitForm();
                  }}
                  size="sm"
                  variant="success"
                >
                  Save Configuration
                </Button>
              </Card.Footer>
            </Card>
            )}
            {/* <Col xs={12}>
              <ProfileCardWidget />
            </Col>
            <Col xs={12}>
              <ChoosePhotoWidget
                title="Select profile photo"
                photo={Profile3}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  );
};
