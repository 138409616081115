import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Table,
  Alert,
  Card,
  Image,
} from "@themesberg/react-bootstrap";
import Notiflix from "notiflix";
import moduleName from "react-datetime";

import { PageVisitsTable } from "../components/Tables";
import { trafficShares, totalOrders } from "../data/charts";

import { useSelector, useDispatch } from "react-redux";
import { setDoctorsList } from "../slices/doctorsSlice";
import { APIService } from "../api.service";

import { CreateEditDoctor } from "../components/CreateEditDoctor";
import moment from "moment-timezone";
import { SpecialityCreateEdit } from "../components/SpecialityCreateEdit";

let lastUpdate = new Date().getTime();
console.log(lastUpdate);

const SpecialitiesPage = ({history}) => {
  const [showCreateEditMdl, setshowCreateEditMdl] = useState(false);
  const [RecordToEdit, setRecordToEdit] = useState(false);
  // const doctors = useSelector((state) => state.doctors);
  const [Records, setRecords] = useState([]);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const getRecords = () => {
    setRecordToEdit(false);
    APIService.get('list-specialities').then( (r)=> { 
      setRecords(r.data.specialities);
      // debugger;
     });

    // APIService.get("list-specialities", {
    //   params: {
    //     // "single": false,
    //     // "withBranches": false
    //   },
    // }).then((response) => {
    //   Notiflix.Notify.info("Fetched Specialities", {
    //     clickToClose: true,
    //     timeout: 2000,
    //   });
    //   debugger;
    //   setRecords(response.data.specialities);
    //   // dispatch(setDoctorsList(response.data.specialities));
    //   lastUpdate = new Date().getTime();
    // });
  };

  useEffect(() => {
    getRecords();
  }, ["state.cron"]);

  // const enableOrDisable = (id, enableOrDisable) => {
  //   Notiflix.Confirm.show(
  //     `${!enableOrDisable ? "Enable" : "Disable"} Doctor/User`,
  //     `Are you sure to ${
  //       !enableOrDisable ? "Enable" : "Disable"
  //     } this Doctor/User`,
  //     "Yes",
  //     "Cancel",
  //     () => {
  //       APIService.post("enable-disable", { id: id }).then((response) => {
  //         Notiflix.Notify.success(response.data.message);
  //         getRecords();
  //       });
  //     }
  //   );
  // };

  const deleteDoctorUser = (id) => {
    Notiflix.Confirm.show(
      `Delete Speciality`,
      `Are you sure to this Speciality?`,
      "Yes",
      "Cancel",
      () => {
        APIService.post("delete-speciality", { id: id }).then((response) => {
          Notiflix.Notify.success(response.data.message);
          getRecords();
        });
      }
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <h4>Specialities</h4>
        {/* <pre>{JSON.stringify(doctors,null,2)}</pre> */}
      </div>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <Row>
            <Col md={6}>
              <Button
                variant="success"
                onClick={(ev) => {
                  setRecordToEdit({});
                  setshowCreateEditMdl(!showCreateEditMdl);
                }}
              >
                Create New Speciality
              </Button>
            </Col>
            <Col md={6}>
              <Button
                variant="success"
                style={{ float: "right" }}
                onClick={getRecords}
              >
                Refresh
              </Button>
            </Col>
          </Row>
          {Records.length > 0 && (
            <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Speciality</td>
                    <td>Image</td>
                    <td>Visible</td>
                    <td>Updated</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {Records.map((record, index) => (
                    <tr key={index}>
                      <td>{record.id}</td>
                      <td>{record.name}</td>
                      <td>
                        <Image
                          rounded
                          src={record.category_img}
                          height={40}
                          alt={"No Image"}
                        ></Image>
                      </td>
                      <td>{record.visible == 1 ? "Yes" : "No"}</td>
                      <td>
                        {moment(record.updated_at).format("DD-MM-YYYY, HH:mm")}
                      </td>
                      <td>
                        <Button
                          variant="info"
                          size="sm"
                          onClick={() => {
                            setRecordToEdit(record);
                            setshowCreateEditMdl(true);
                          }}
                        >
                          EDIT
                        </Button>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={(e) => {
                            deleteDoctorUser(record.id);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>
      {showCreateEditMdl && (
        <SpecialityCreateEdit
          record={RecordToEdit}
          onHide={() => {
            setshowCreateEditMdl(false);
          }}
          callbackOnSucces={() => {
            getRecords();
          }}
        />
      )}

      {/* {showCreateEditMdl && (
        <CreateEditDoctor
          show={true}
          onHide={() => {
            setShowCreateEditDoctor(false);
          }}
          title={"Create new doctor account"}
          recordToEdit={RecordToEdit}
          callbackOnSucces={getRecords}
        />
      )} */}
    </>
  );
};
export default SpecialitiesPage;
