import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import About from './About';
import Dashboard from "./Dashboard";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import MerchantsList from './MerchantsList';
import MerchantsCreate from './MerchantsCreate';
import HolidaysList from './HolidaysList';
import HolidaysCreate from './HolidaysCreate';
import DoctorsList from './DoctorsList';
import CronsCreate from './CronsCreate';
import CronLogsList from './CronLogsList';
import UsersList from './UsersList';
import SpecialitiesPage from './SpecialitiesPage';
import ComingSoon from './examples/ComingSoon';
import MSettings from './MSettings';
import Payments from './Payments';
import LandingPage from './LandingPage';
import PrivacyPolicy from './PrivacyPolicy';
import ContactUs from './ContactUs';
import ConsultationLogs from './ConsultationLogs';
import VerifyPage from './VerifyPage';
import DeleteAccount from './DeleteAccount';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  // console.log(rest, Component);
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);
  // console.log(!sessionStorage.user, rest);

  const routesWithoutAuth = [
    '/admin/',
    Routes.PrivacyPolicy.path,
    Routes.ContactUs.path,
    Routes.Verify.path,
    Routes.DeleteAccount.path,
  ];

  if(routesWithoutAuth.includes(rest.path)){
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <div>
              {" "}
              <Preloader show={loaded ? false : true} />{" "}
              <Component {...props} />{" "}
            </div>
          );
        }}
      />
    );  
  }

  if (!sessionStorage.user) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <>
            {" "}
            <Preloader show={loaded ? false : true} />
            <LandingPage {...props} />
            {/* <Signin {...props} /> */}
          </>
        )}
      />
    );
  }

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }
  if(!sessionStorage.user){
    return (<Redirect to={Routes.Signin.path} />)
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default (props) => {
  return (
    <Switch>
      <RouteWithLoader exact path={Routes.Landing.path} component={LandingPage} />
      <RouteWithLoader exact path={Routes.DeleteAccount.path} component={DeleteAccount} />
      <RouteWithLoader path={Routes.Verify.path} component={VerifyPage} />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.PrivacyPolicy.path} component={PrivacyPolicy} />
      <RouteWithLoader exact path={Routes.ContactUs.path} component={ContactUs} />
      
      {/* <RouteWithLoader exact path={Routes.Signup.path} component={Signup} /> */}
      <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
      <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
      {/* <RouteWithLoader exact path={Routes.Lock.path} component={Lock} /> */}
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
      <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

      {/* pages */}
      <RouteWithSidebar exact path={Routes.About.path} component={About} />
      <RouteWithSidebar exact path={Routes.Dashboard.path} component={Dashboard} />
      <RouteWithSidebar exact path={Routes.MerchantsList.path} component={MerchantsList} />
      <RouteWithSidebar exact path={Routes.MerchantsCreate.path} component={MerchantsCreate} />
      <RouteWithSidebar exact path={Routes.Payments.path} component={Payments} />
      <RouteWithSidebar exact path={Routes.Logs.path} component={ConsultationLogs} />
      <RouteWithSidebar exact path={Routes.Settings.path} component={MSettings} />

      <RouteWithSidebar exact path={Routes.DoctorsList.path} component={DoctorsList} />
      <RouteWithSidebar exact path={Routes.UsersList.path} component={UsersList} />
      <RouteWithSidebar exact path={Routes.Specialities.path} component={SpecialitiesPage} />

      <RouteWithSidebar exact path={Routes.CronsCreate.path} component={CronsCreate} />
      <RouteWithSidebar exact path={Routes.CronLogsList.path} component={CronLogsList} />

      <RouteWithSidebar exact path={Routes.HolidaysList.path} component={HolidaysList} />
      <RouteWithSidebar exact path={Routes.HolidaysCreate.path} component={HolidaysCreate} />

      <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
      <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
      {/* <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} /> */}
      <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />

      {/* components */}
      <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
      <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
      <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
      <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
      <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
      <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
      <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
      <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
      <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
      <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
      <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
      <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
      <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
      <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
      <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
      <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

      {/* documentation */}
      <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
      <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
      <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
      <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
      <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
      <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
      <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />
      
      <Redirect to={Routes.NotFound.path} />
    </Switch>
)
};
