import { createSlice } from '@reduxjs/toolkit';
import API from '../api.service';

export const appSlice = createSlice({
  name: 'APP',
  initialState: {
    price: false, 
    enablePrice: 0,
    freeSubscription: 0,
  },
  reducers: {
    setAppConfig: (state, action) => {
      state = {...state, ...action.payload };
      return state;
    },
  },
});

export const { setAppConfig } = appSlice.actions;
export default appSlice.reducer;
