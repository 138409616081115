import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Navbar,
  Nav,
  NavDropdown,
} from "@themesberg/react-bootstrap";

import { Link } from "react-router-dom";
import { Routes } from "../routes";

import logo from "../assets/img/logo.png";
import bg from "../assets/img/bg.jpg";

import $ from "jquery";
// import '../assets/theme/js/active';
import "../assets/theme/css/responsive.css";
import "../assets/theme/style.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    $("#preloader").fadeOut("slow", function () {
      $(this).remove();
    });
    return () => {
      // second
    };
  }, []);

  const PricingPlans = () => {
    return (
      <section
        className="pricing-plan-area section_padding_100_70 clearfix"
        id="pricing"
      >
        <div className="container">
          <div className="row no-gutters">
            {/* <!-- Single Price Plan --> */}
            <div className="col-12 col-md-4">
              {/* <!-- Package Price  --> */}
              <div className="single-price-plan text-center">
                {/* <!-- Package Head --> */}
                <div className="package-head">
                  <p className="sub-title">begginer</p>
                  <div className="ca-price d-flex justify-content-center">
                    <h4>Free</h4>
                  </div>
                  <p className="plan-period">Always</p>
                </div>
                {/* <!-- Package Description --> */}
                <div className="package-description">
                  <p>2 E-mail Addresess</p>
                  <p>25 GB Space</p>
                  <p>24h Live Support</p>
                  <p>Documetation inside</p>
                  <p>SEO Plan</p>
                </div>
                {/* <!-- Package Info --> */}
                <div className="package-info">
                  <a href="#">
                    <i className="fa fa-info"></i>
                  </a>
                </div>
                {/* <!-- Order Button  --> */}
                <div className="plan-button">
                  <a href="#">order plan</a>
                </div>
              </div>
            </div>

            {/* <!-- Single Price Plan --> */}
            <div className="col-12 col-md-4">
              {/* <!-- Package Price  --> */}
              <div className="single-price-plan active text-center">
                {/* <!-- Package Head --> */}
                <div className="package-head">
                  <p className="sub-title">Recommended</p>
                  <div className="ca-price d-flex justify-content-center">
                    <h4>$29</h4>
                    <span>90</span>
                  </div>
                  <p className="plan-period">Per Month</p>
                </div>
                {/* <!-- Package Description --> */}
                <div className="package-description">
                  <p>2 E-mail Addresess</p>
                  <p>25 GB Space</p>
                  <p>24h Live Support</p>
                  <p>Documetation inside</p>
                  <p>SEO Plan</p>
                </div>
                {/* <!-- Package Info --> */}
                <div className="package-info">
                  <a href="#">
                    <i className="fa fa-info"></i>
                  </a>
                </div>
                {/* <!-- Order Button  --> */}
                <div className="plan-button">
                  <a href="#">order plan</a>
                </div>
              </div>
            </div>

            {/* <!-- Single Price Plan --> */}
            <div className="col-12 col-md-4">
              {/* <!-- Package Price  --> */}
              <div className="single-price-plan text-center">
                {/* <!-- Package Head --> */}
                <div className="package-head">
                  <p className="sub-title">Professional</p>
                  <div className="ca-price d-flex justify-content-center">
                    <h4>$59</h4>
                    <span>90</span>
                  </div>
                  <p className="plan-period">Per Month</p>
                </div>
                {/* <!-- Package Description --> */}
                <div className="package-description">
                  <p>2 E-mail Addresess</p>
                  <p>25 GB Space</p>
                  <p>24h Live Support</p>
                  <p>Documetation inside</p>
                  <p>SEO Plan</p>
                </div>
                {/* <!-- Package Info --> */}
                <div className="package-info">
                  <a href="#">
                    <i className="fa fa-info"></i>
                  </a>
                </div>
                {/* <!-- Order Button  --> */}
                <div className="plan-button">
                  <a href="#">order plan</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  const AppFeatures = () => {
    return (
      <section className="app-features-area">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="app-features-content">
                <h2>All the best features</h2>
                <div className="row">
                  {/* <!-- Single Feature Area --> */}
                  <div className="col-12 col-sm-6">
                    <div className="single-feature-area">
                      <img src="img/bg-img/feature-1.png" alt="" />
                      <h5>No1 in Sales!</h5>
                      <p>
                        Etiam nec odio vestibulum est mat tis effic iturut
                        magna.
                      </p>
                    </div>
                  </div>
                  {/* <!-- Single Feature Area --> */}
                  <div className="col-12 col-sm-6">
                    <div className="single-feature-area">
                      <img src="img/bg-img/feature-2.png" alt="" />
                      <h5>Online Marketing</h5>
                      <p>
                        Etiam nec odio vestibulum est mat tis effic iturut
                        magna.
                      </p>
                    </div>
                  </div>
                  {/* <!-- Single Feature Area --> */}
                  <div className="col-12 col-sm-6">
                    <div className="single-feature-area">
                      <img src="img/bg-img/feature-3.png" alt="" />
                      <h5>Modern Design</h5>
                      <p>
                        Etiam nec odio vestibulum est mat tis effic iturut
                        magna.
                      </p>
                    </div>
                  </div>
                  {/* <!-- Single Feature Area --> */}
                  <div className="col-12 col-sm-6">
                    <div className="single-feature-area">
                      <img src="img/bg-img/feature-4.png" alt="" />
                      <h5>Documented</h5>
                      <p>
                        Etiam nec odio vestibulum est mat tis effic iturut
                        magna.
                      </p>
                    </div>
                  </div>
                  {/* <!-- Single Feature Area --> */}
                  <div className="col-12 col-sm-6">
                    <div className="single-feature-area">
                      <img src="img/bg-img/feature-5.png" alt="" />
                      <h5>Easy to use</h5>
                      <p>
                        Etiam nec odio vestibulum est mat tis effic iturut
                        magna.
                      </p>
                    </div>
                  </div>
                  {/* <!-- Single Feature Area --> */}
                  <div className="col-12 col-sm-6">
                    <div className="single-feature-area">
                      <img src="img/bg-img/feature-6.png" alt="" />
                      <h5>Google Font</h5>
                      <p>
                        Etiam nec odio vestibulum est mat tis effic iturut
                        magna.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- App Feature Thumb --> */}
        <div className="app-feature-side-thumb">
          <img src="img/bg-img/feature-side.jpg" alt="" />
        </div>
      </section>
    );
  };
  return (
    <>
      {/* <!-- ***** Preloader Start ***** --> */}
      <div id="preloader">
        <div className="selene-preloader"></div>
      </div>

      {/* <!-- ***** Header Area Start ***** --> */}
      <header className="header_area animated">
        <div className="container-fluid">
          <div className="row align-items-center">
            {/* <!-- Menu Area Start --> */}
            <div className="col-12">
              <div className="menu_area">
                <nav className="navbar navbar-expand-lg navbar-light">
                  {/* <!-- Logo --> */}
                  <a
                    className="navbar-brand"
                    href="#"
                    style={{ marginLeft: "-75px", marginTop: "25px" }}
                  >
                    <img
                      src={logo}
                      style={{ width: "200px", height: "200px" }}
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#selene-navbar"
                    aria-controls="selene-navbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  {/* <!-- Menu Area --> */}
                  <div className="collapse navbar-collapse" id="selene-navbar">
                    <ul className="navbar-nav ml-auto" id="nav">
                      <li className="nav-item active">
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>
                      {/* <li className="nav-item dropdown">
                                        <div className="dropdown-menu" aria-labelledby="seleneDropdown">
                                            <a className="dropdown-item" href="index.html">Home</a>
                                        </div>
                                    </li> */}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ***** Header Area End ***** --> */}

      {/* <!-- ***** Wellcome Area Start ***** --> */}
      <section className="welcome_area clearfix" id="home">
        {/* <!-- Shape --> */}
        <div className="welcome-bg-shape">
          <img
            className="welcome-first-shape"
            src="img/core-img/welcome-bg-1.png"
            alt=""
          />
          <img
            className="welcome-second-shape"
            src="img/core-img/welcome-bg-2.png"
            alt=""
          />
        </div>

        <div className="container h-100">
          <div className="row h-100 align-items-center pt-10 ">
            <div className="col-12 pb-9">
              <p>
                Mobidoctor (“us”, “we”, or “our”) operates the
                https://www.mobidoctor.org/ website (hereinafter referred to as
                the “Service”).
              </p>

              <p>
                <b>Effective: March 23rd 2023.</b>
              </p>
              <p>
                This page informs you of our policies regarding the collection,
                use, and disclosure of personal data when you use our Service
                and the choices you have associated with that data.
              </p>

              <p>
                We use your data to provide and improve the Service. By using
                the Service, you agree to the collection and use of information
                in accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions, accessible from
                Mobidoctor.org
              </p>

              <h5>Information Collection And Use</h5>
              <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>

              <h4>Types of Data Collected</h4>
              <h5>Personal Data</h5>
              <p>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (“Personal Data”). Personally
                identifiable information may include, but is not limited to:
                <br></br>
                Email address
                <br></br>
                First name and last name
                <br></br>
                Cookies and Usage Data
              </p>

              <h5>Usage Data</h5>
              <p>
                We may also collect information on how the Service is accessed
                and used (“Usage Data”). This Usage Data may include information
                such as your computer’s Internet Protocol address (e.g. IP
                address), browser type, browser version, the pages of our
                Service that you visit, the time and date of your visit, the
                time spent on those pages, unique device identifiers and other
                diagnostic data.
              </p>

              <h5>Tracking & Cookies Data</h5>
              <p>
                We use cookies and similar tracking technologies to track the
                activity on our Service and hold certain information.
              </p>

              <p>
                Cookies are files with small amount of data which may include an
                anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Tracking technologies
                also used are beacons, tags, and scripts to collect and track
                information and to improve and analyze our Service.
              </p>

              <p>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </p>

              <h5>Examples of Cookies we use:</h5>

              <li>
                Session Cookies. We use Session Cookies to operate our Service.
              </li>
              <li>
                Preference Cookies. We use Preference Cookies to remember your
                preferences and various settings.
              </li>
              <li>
                Security Cookies. We use Security Cookies for security purposes.
              </li>

              <h4>Use of Data</h4>
              <p>
                Mobidoctor.org uses the collected data for various purposes:
              </p>

              <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to our Service</li>
                <li>
                  To allow you to participate in interactive features of our
                  Service when you choose to do so
                </li>
                <li>To provide customer care and support</li>
                <li>
                  To provide analysis or valuable information so that we can
                  improve the Service
                </li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
              </ul>
              <h5>Transfer Of Data</h5>
              <p>
                Your information, including Personal Data, may be transferred to
                — and maintained on — computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ than those from your
                jurisdiction.
              </p>

              <p>
                If you are located outside Kenya and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to Kenya and process it there.
              </p>

              <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>

              <p>
                Mobidoctor.org will take all steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this Privacy Policy and no transfer of your Personal Data will
                take place to an organization or a country unless there are
                adequate controls in place including the security of your data
                and other personal information.
              </p>

              <h4>Disclosure Of Data</h4>
              <h5>Legal Requirements</h5>
              <p>
                Mobidoctor.org may disclose your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ul>
                <li>To comply with a legal obligation</li>
                <li>
                  To protect and defend the rights or property of Mobidoctor.org
                </li>
                <li>
                  To prevent or investigate possible wrongdoing in connection
                  with the Service
                </li>
                <li>
                  To protect the personal safety of users of the Service or the
                  public
                </li>
                <li>To protect against legal liability</li>
              </ul>
              <h4>Security Of Data</h4>
              <p>
                The security of your data is important to us, but remember that
                no method of transmission over the Internet, or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>

              <h4>Service Providers</h4>
              <p>
                We may employ third party companies and individuals to
                facilitate our Service (“Service Providers”), to provide the
                Service on our behalf, to perform Service-related services or to
                assist us in analyzing how our Service is used.
              </p>

              <p>
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>

              <h4>Analytics</h4>
              <p>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>

              <h6>Google Analytics</h6>
              <p>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network.You can opt-out of having made your
                activity on the Service available to Google Analytics by
                installing the Google Analytics opt-out browser add-on. The
                add-on prevents the Google Analytics JavaScript (ga.js,
                analytics.js, and dc.js) from sharing information with Google
                Analytics about visits activity.For more information on the
                privacy practices of Google, please visit the Google Privacy &
                Terms web page: https://policies.google.com/privacy?hl=en
              </p>
              <h6>Links To Other Sites</h6>
              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click on a third party link, you will be
                directed to that third party’s site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>

              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>

              <h4>Children’s Privacy</h4>
              <p>
                Our Service does not address anyone under the age of 18
                (“Children”).
              </p>

              <p>
                We do not knowingly collect personally identifiable information
                from anyone under the age of 18. If you are a parent or guardian
                and you are aware that your Children has provided us with
                Personal Data, please contact us. If we become aware that we
                have collected Personal Data from children without verification
                of parental consent, we take steps to remove that information
                from our servers.
              </p>

              <h4>Changes To This Privacy Policy</h4>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>

              <p>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                “effective date” at the top of this Privacy Policy.
              </p>

              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>

              <h4>Contact Us</h4>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us:<br></br>
                By visiting this page on our website:{" "}
                <a href="//mobidoctor.org/contact-us">
                  www.mobidoctor.org/contact-us
                </a>
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** Wellcome Area End ***** --> */}

      {/* <!-- ***** App Feature Area Start ***** --> */}
      {/* {AppFeatures()} */}
      {/* <!-- ***** App Feature Area End ***** --> */}

      {/* <!-- ***** Pricing Plane Area Start ***** --> */}
      {/* {PricingPlans()} */}
      {/* <!-- ***** Pricing Plane Area End ***** --> */}

      {/* <!-- ***** Footer Area Start ***** --> */}
      <footer className="footer-area text-center clearfix">
        {/* <!-- Shape 1 --> */}
        <div className="footer-bg-shape">
          <img
            src="img/core-img/footer-bg.png"
            style={{ height: "243px", width: "100%" }}
            alt="Footer Shape"
          />
        </div>
        {/* <!-- Shape 2 --> */}
        {/* <div className="footer-bg-shape-2">
            <img src="img/core-img/price-table.png" alt="" />
        </div> */}

        <div className="container h-100">
          <div className="row h-100 justify-content-center">
            <div className="col-12 col-md-8">
              {/* <div className="footer-content">
                        <h2>Download App</h2>
                        <h4>Get you first consultation free. </h4>
                    </div>
                    <div className="app-download-area">
                        <div className="app-download-btn active">
                            <!-- Google Store Btn -->
                            <a href="#"><i className="fa fa-android"></i><p className="mb-0"><span>available on</span> Google Store</p></a>
                        </div>
                        <div className="app-download-btn">
                            <!-- Apple Store Btn -->
                            <a href="#"><i className="fa fa-apple"></i><p className="mb-0"><span>available on</span> Apple Store</p></a>
                        </div>
                    </div> */}
              <div className="footer-social-info">
                {/* <a href="#">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a> */}
                <Nav.Link className="" title="Privacy policy" href={Routes.PrivacyPolicy.path}>
                  Privacy policy
                </Nav.Link>
                <Nav.Link className="" title="Contact Us" href={Routes.ContactUs.path}>
                  Contact Us
                </Nav.Link>
              </div>
              {/* <!-- Foooter Text--> */}
              <div className="copyright-text">
                <p>
                  {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script> All
                  rights reserved{" "}
                  <i className="fa fa-heart-o" aria-hidden="true"></i> by{" "}
                  <a href="https://mobidoctor.org" target="_blank">
                    Mobidoctor
                  </a>
                  {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ***** Footer Area End ***** --> */}
      {/* </div> */}
    </>
  );
};
export default PrivacyPolicy;
