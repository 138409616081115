import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  Navbar,
  Nav,
  NavDropdown,
} from "@themesberg/react-bootstrap";

import { Link } from "react-router-dom";
import { Routes } from "../routes";

import logo from "../assets/img/logo.png";
import bg from "../assets/img/bg.jpg";

import $ from 'jquery';
// import '../assets/theme/js/active';
import "../assets/theme/css/responsive.css";
import "../assets/theme/style.css";

export default () => {
    useEffect(() => {
        $('#preloader').fadeOut('slow', function () {
            $(this).remove();
        });
      return () => {
        // second
      }
    }, [])
    
    const PricingPlans = () => {
      return (
        <section
          className="pricing-plan-area section_padding_100_70 clearfix"
          id="pricing"
        >
          <div className="container">
            <div className="row no-gutters">
              {/* <!-- Single Price Plan --> */}
              <div className="col-12 col-md-4">
                {/* <!-- Package Price  --> */}
                <div className="single-price-plan text-center">
                  {/* <!-- Package Head --> */}
                  <div className="package-head">
                    <p className="sub-title">begginer</p>
                    <div className="ca-price d-flex justify-content-center">
                      <h4>Free</h4>
                    </div>
                    <p className="plan-period">Always</p>
                  </div>
                  {/* <!-- Package Description --> */}
                  <div className="package-description">
                    <p>2 E-mail Addresess</p>
                    <p>25 GB Space</p>
                    <p>24h Live Support</p>
                    <p>Documetation inside</p>
                    <p>SEO Plan</p>
                  </div>
                  {/* <!-- Package Info --> */}
                  <div className="package-info">
                    <a href="#">
                      <i className="fa fa-info"></i>
                    </a>
                  </div>
                  {/* <!-- Order Button  --> */}
                  <div className="plan-button">
                    <a href="#">order plan</a>
                  </div>
                </div>
              </div>

              {/* <!-- Single Price Plan --> */}
              <div className="col-12 col-md-4">
                {/* <!-- Package Price  --> */}
                <div className="single-price-plan active text-center">
                  {/* <!-- Package Head --> */}
                  <div className="package-head">
                    <p className="sub-title">Recommended</p>
                    <div className="ca-price d-flex justify-content-center">
                      <h4>$29</h4>
                      <span>90</span>
                    </div>
                    <p className="plan-period">Per Month</p>
                  </div>
                  {/* <!-- Package Description --> */}
                  <div className="package-description">
                    <p>2 E-mail Addresess</p>
                    <p>25 GB Space</p>
                    <p>24h Live Support</p>
                    <p>Documetation inside</p>
                    <p>SEO Plan</p>
                  </div>
                  {/* <!-- Package Info --> */}
                  <div className="package-info">
                    <a href="#">
                      <i className="fa fa-info"></i>
                    </a>
                  </div>
                  {/* <!-- Order Button  --> */}
                  <div className="plan-button">
                    <a href="#">order plan</a>
                  </div>
                </div>
              </div>

              {/* <!-- Single Price Plan --> */}
              <div className="col-12 col-md-4">
                {/* <!-- Package Price  --> */}
                <div className="single-price-plan text-center">
                  {/* <!-- Package Head --> */}
                  <div className="package-head">
                    <p className="sub-title">Professional</p>
                    <div className="ca-price d-flex justify-content-center">
                      <h4>$59</h4>
                      <span>90</span>
                    </div>
                    <p className="plan-period">Per Month</p>
                  </div>
                  {/* <!-- Package Description --> */}
                  <div className="package-description">
                    <p>2 E-mail Addresess</p>
                    <p>25 GB Space</p>
                    <p>24h Live Support</p>
                    <p>Documetation inside</p>
                    <p>SEO Plan</p>
                  </div>
                  {/* <!-- Package Info --> */}
                  <div className="package-info">
                    <a href="#">
                      <i className="fa fa-info"></i>
                    </a>
                  </div>
                  {/* <!-- Order Button  --> */}
                  <div className="plan-button">
                    <a href="#">order plan</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    };
    const AppFeatures = () => {
        return (<section className="app-features-area">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="app-features-content">
                        <h2>All the best features</h2>
                        <div className="row">
                            {/* <!-- Single Feature Area --> */}
                            <div className="col-12 col-sm-6">
                                <div className="single-feature-area">
                                    <img src="img/bg-img/feature-1.png" alt="" />
                                    <h5>No1 in Sales!</h5>
                                    <p>Etiam nec odio vestibulum est mat tis effic iturut magna.</p>
                                </div>
                            </div>
                            {/* <!-- Single Feature Area --> */}
                            <div className="col-12 col-sm-6">
                                <div className="single-feature-area">
                                    <img src="img/bg-img/feature-2.png" alt="" />
                                    <h5>Online Marketing</h5>
                                    <p>Etiam nec odio vestibulum est mat tis effic iturut magna.</p>
                                </div>
                            </div>
                            {/* <!-- Single Feature Area --> */}
                            <div className="col-12 col-sm-6">
                                <div className="single-feature-area">
                                    <img src="img/bg-img/feature-3.png" alt="" />
                                    <h5>Modern Design</h5>
                                    <p>Etiam nec odio vestibulum est mat tis effic iturut magna.</p>
                                </div>
                            </div>
                            {/* <!-- Single Feature Area --> */}
                            <div className="col-12 col-sm-6">
                                <div className="single-feature-area">
                                    <img src="img/bg-img/feature-4.png" alt="" />
                                    <h5>Documented</h5>
                                    <p>Etiam nec odio vestibulum est mat tis effic iturut magna.</p>
                                </div>
                            </div>
                            {/* <!-- Single Feature Area --> */}
                            <div className="col-12 col-sm-6">
                                <div className="single-feature-area">
                                    <img src="img/bg-img/feature-5.png" alt="" />
                                    <h5>Easy to use</h5>
                                    <p>Etiam nec odio vestibulum est mat tis effic iturut magna.</p>
                                </div>
                            </div>
                            {/* <!-- Single Feature Area --> */}
                            <div className="col-12 col-sm-6">
                                <div className="single-feature-area">
                                    <img src="img/bg-img/feature-6.png" alt="" />
                                    <h5>Google Font</h5>
                                    <p>Etiam nec odio vestibulum est mat tis effic iturut magna.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        {/* <!-- App Feature Thumb --> */}
        <div className="app-feature-side-thumb">
            <img src="img/bg-img/feature-side.jpg" alt="" />
        </div>
    </section>);
    }
    return (<>
    {/* <!-- ***** Preloader Start ***** --> */}
    <div id="preloader">
        <div className="selene-preloader"></div>
    </div>

    {/* <!-- ***** Header Area Start ***** --> */}
    <header className="header_area animated">
        <div className="container-fluid">
            <div className="row align-items-center">
                {/* <!-- Menu Area Start --> */}
                <div className="col-12">
                    <div className="menu_area">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            {/* <!-- Logo --> */}
                            <a className="navbar-brand" href="#" style={{marginLeft: '-75px', marginTop:'25px'}}>
                                <img src={logo} style={{width: '200px', height: '200px'}} />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#selene-navbar" aria-controls="selene-navbar" aria-expanded="false" aria-label="Toggle navigation">
                              <span className="navbar-toggler-icon" onClick={()=>{window.location.href = '/admin'}}></span>
                            </button>
                            {/* <!-- Menu Area --> */}
                            <div className="collapse navbar-collapse" id="selene-navbar">
                                <ul className="navbar-nav ml-auto" id="nav">
                                    <li className="nav-item active"><a className="nav-link" href="/admin">Dashboard</a></li>
                                    {/* <li className="nav-item dropdown">
                                        <div className="dropdown-menu" aria-labelledby="seleneDropdown">
                                            <a className="dropdown-item" href="index.html">Home</a>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    {/* <!-- ***** Header Area End ***** --> */}


    {/* <!-- ***** Wellcome Area Start ***** --> */}
    <section className="welcome_area clearfix" id="home">

        {/* <!-- Shape --> */}
        <div className="welcome-bg-shape">
            <img className="welcome-first-shape" src="img/core-img/welcome-bg-1.png" alt="" />
            <img className="welcome-second-shape" src="img/core-img/welcome-bg-2.png" alt="" />
        </div>

        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12 col-md-7">
                    <div className="welcome-content">
                        <h2><span>Consult your online Doctor.</span></h2>
                        <h4>Easy access to medical healthcare experts.</h4>
                            <ul>
                                <li style={{listStyle: 'decimal', listStylePosition: 'inside'}}>Easy access to medical healthcare experts.</li>
                                <li style={{listStyle: 'decimal', listStylePosition: 'inside'}}>24/7 healthcare services.</li>
                                <li style={{listStyle: 'decimal', listStylePosition: 'inside'}}>Delivery of drugs and medication at your door step.</li>
                            </ul>
                    </div>
                    <div className="app-download-area">
                        <div className="app-download-btn active">
                            {/* <!-- Google Store Btn --> */}
                            <a href="#"><i className="fa fa-android"></i><p className="mb-0"><span>available on</span> Google Store</p></a>
                        </div>
                        <div className="app-download-btn">
                            {/* <!-- Apple Store Btn --> */}
                            <a href="#"><i className="fa fa-apple"></i><p className="mb-0"><span>available on</span> Apple Store</p></a>
                        </div>
                    </div>
                    
                    {/* <!-- Scroll btn --> */}
                    <div className="scroll-down-btn">
                        <a href="#about" className="scrollDown">Scroll Down <i className="fa fa-angle-double-down scroll-down" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Mobile Device --> */}
        <div className="welcome-mobile-device text-right d-none d-lg-block" style={{position: 'fixed'}}>
            <img src="img/bg-img/wel-dev-1.png" alt="" />
            {/* <img src="img/bg-img/wel-dev-2.png" alt="" /> */}
        </div>
    </section>
    {/* <!-- ***** Wellcome Area End ***** --> */}

    

    {/* <!-- ***** App Feature Area Start ***** --> */}
    {/* {AppFeatures()} */}
    {/* <!-- ***** App Feature Area End ***** --> */}

    {/* <!-- ***** Pricing Plane Area Start ***** --> */}
    {/* {PricingPlans()} */}
    {/* <!-- ***** Pricing Plane Area End ***** --> */}

    {/* <!-- ***** Footer Area Start ***** --> */}
    <footer className="footer-area text-center clearfix">
        {/* <!-- Shape 1 --> */}
        <div className="footer-bg-shape">
            <img src="img/core-img/footer-bg.png" style={{height: '243px', width: '100%'}} alt="Footer Shape" />
        </div>
        {/* <!-- Shape 2 --> */}
        {/* <div className="footer-bg-shape-2">
            <img src="img/core-img/price-table.png" alt="" />
        </div> */}

        <div className="container h-100">
            <div className="row h-100 justify-content-center">
                <div className="col-12 col-md-8" style={{height: '158px'}}>
                    {/* <div className="footer-content">
                        <h2>Download App</h2>
                        <h4>Get you first consultation free. </h4>
                    </div>
                    <div className="app-download-area">
                        <div className="app-download-btn active">
                            <!-- Google Store Btn -->
                            <a href="#"><i className="fa fa-android"></i><p className="mb-0"><span>available on</span> Google Store</p></a>
                        </div>
                        <div className="app-download-btn">
                            <!-- Apple Store Btn -->
                            <a href="#"><i className="fa fa-apple"></i><p className="mb-0"><span>available on</span> Apple Store</p></a>
                        </div>
                    </div> */}
                    <div className="footer-social-info">
                        {/* <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                        <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                        <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a> */}
                        <Nav.Link className="" title="Privacy policy" href={Routes.PrivacyPolicy.path}>
                          Privacy policy
                        </Nav.Link>
                        <Nav.Link className="" title="Contact Us" href={Routes.ContactUs.path}>
                          Contact Us
                        </Nav.Link>
                        {/* <a href="/privacy-policy">Privacy policy</a>
                        <a href="/contact-us">Contact us</a> */}
                    </div>
                    {/* <!-- Foooter Text--> */}
                    <div className="copyright-text">
                        <p>
                            {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved  <i className="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://mobidoctor.org" target="_blank">Mobidoctor</a>
{/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    {/* <!-- ***** Footer Area End ***** --> */}
    {/* </div> */}
    </>);
  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} style={{ height: 200 }} />
            MobiDoctor
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{minHeight: 100, backgroundImage:`url('${bg}')`}}>
        sdfsfd <br/>
        sdfsfd <br/>
        sdfsfd <br/>
        sdfsfd <br/>
      </div>
    </div>
  );
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col
              xs={12}
              className="text-center d-flex align-items-center justify-content-center"
            >
              <div>
                {/* <Card.Link as={Link} to={Routes.Dashboard.path}>
                  <Image src={NotFoundImage} className="img-fluid w-75" />
                </Card.Link> */}
                <h1 className="text-primary mt-5">Landing Page</h1>
                <p className="lead my-4">
                  Oops! Looks like you followed a bad link. If you think this is
                  a problem with us, please tell us.
                </p>
                <Button
                  as={Link}
                  variant="primary"
                  className="animate-hover"
                  to={Routes.Signin.path}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="animate-left-3 me-3 ms-2"
                  />
                  Go back home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
