import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  faCashRegister,
} from '@fortawesome/free-solid-svg-icons';
import { Col, Row,  Table, Card, Tab,} from '@themesberg/react-bootstrap';

import {
  CounterWidget,
} from '../components/Widgets';
import { PageVisitsTable } from '../components/Tables';
import { trafficShares, totalOrders } from '../data/charts';

import { useSelector, useDispatch } from 'react-redux';
import { APIService } from './../api.service';
import { setDashboard } from '../slices/loginSlice';
import AccordionComponent from '../components/AccordionComponent';

let lastUpdate = new Date().getTime();
// console.log(lastUpdate);
const ConsultationLogs = () => {
  const dashboard = useSelector((state) => state.login.dashboard);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [Records, setRecords] = useState([]);

  const getConsultationLogs = () => {
    APIService.post('/consultation-logs').then((response) => {
      // dispatch(setDashboard(response.data));
      setRecords(response.data.data);
      lastUpdate = new Date().getTime();
    });
  };

  useEffect(() => {
    getConsultationLogs();
  }, ['state.login']);

  const fancyTimeFormat = (duration) => {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
  
    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
  
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
  
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
  
    return ret;
  }
  
  return (
    <>
      <Row className="justify-content-md-center">
        <Table>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Cost</th>
            <th></th>
          </tr>
          {Records.map(rec => {
            return (
            <tr>
              <td>{rec.fromUser.full_name}</td>
              <td>{rec.com_type} consultation for { fancyTimeFormat(rec.t_duration)} with {rec.toUser.full_name} ({rec.toUser.email})</td>
              <td>{rec.t_cost}</td>
            </tr>
          )})}
        </Table>
        {/* <Col xs={6} className="mb-4 d-none d-sm-block">
          {dashboard && (
            <Table striped bordered hover size="sm">
              <tbody>
                <tr onClick={getConsultationLogs}>
                  <td>AppType</td>
                  <td>{dashboard.version.appType}</td>
                </tr>
                <tr>
                  <td>Version</td>
                  <td>{dashboard.version.version}</td>
                </tr>
                <tr>
                  <td>Branch Name</td>
                  <td>{dashboard.version.branchName}</td>
                </tr>
                <tr>
                  <td>CommitID</td>
                  <td>{dashboard.version.commitId}</td>
                </tr>
                <tr>
                  <td>Last Updated</td>
                  <td>{dashboard.version.pushTime}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Col>
        <Col xs={6} className="mb-4 d-none d-sm-block">
          {dashboard && (
            <Table striped bordered hover size="sm">
              <tbody>
                <tr>
                  <td>NodeJS Version</td>
                  <td>{dashboard.environment.node}</td>
                </tr>
                <tr>
                  <td>NPM Version</td>
                  <td>{dashboard.environment.npm}</td>
                </tr>
                <tr>
                  <td>Prisma Version</td>
                  <td>{dashboard.environment.prisma}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Col> */}
      </Row>
    </>
  );
};
export default ConsultationLogs;
